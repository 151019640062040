import dynamic from 'next/dynamic'
import { usePrevious } from '@bluheadless/ui-logic/src/hooks/usePrevious'
import { useConfig } from '@bluheadless/ui-logic/src/providers/config'
import { useHeaderHeight, useHeaderHeightFilled } from '@bluheadless/ui-logic/src/providers/header'
import { useMenus } from '@bluheadless/ui-logic/src/providers/menus'
import { useMemo } from 'react'
import Footer from '../../organisms/footer/footer'
import Header from '../../organisms/header/header'
import StickyHeadroomAutoHeight from '../../particles/sticky-headroom-auto-height'
import Main from '../main'
import SalesAgentNotice from '../../organisms/sales-agent-notice/sales-agent-notice'
import SuggestedStore from '../../organisms/suggested-store/suggested-store'

const DemoNotice = dynamic(() => import('../../molecules/demo-notice'))

const MainLayout = ({ children, layout: layoutProps }) => {
	const { mainMenu, footerMenu } = useMenus()
	const {
		stickyHeader: { enabled: stickyHeaderEnabled, hideOnScrollDown: stickyHeaderHideOnScrollDown },
		layout: { default: layoutConfig },
		demoNotice,
	} = useConfig()

	const layout = useMemo(() => ({ ...layoutConfig, ...layoutProps }), [layoutConfig, layoutProps])

	const { headerType, headerVariant, headerLook, footerVariant } = layout

	const [{ height: headerHeight }] = useHeaderHeight()

	useHeaderHeightFilled(headerType, headerHeight)

	// keep in sync with bluheadless/packages/ui/src/particles/sticky-headroom/sticky-headroom.js:39
	const overlap = headerType === 'transparent' || headerType === 'custom_1' || headerType === 'custom_2'

	const ignoreBeforeContentSpace = stickyHeaderEnabled && (!!headerHeight || overlap) // keep in sync with bluheadless/packages/ui/src/particles/sticky-headroom/sticky-headroom.js:34

	const previousHeaderHeight = usePrevious(headerHeight)

	const mainContainerMarginTopTransition = previousHeaderHeight > 0 ? 'margin-top 0.2s ease-out' : 'none'

	const beforeContent = useMemo(
		() => (
			<StickyHeadroomAutoHeight
				disabled={!stickyHeaderEnabled}
				hideOnScrollDown={stickyHeaderHideOnScrollDown}
				overlap={overlap}
				look={headerLook}
				zIndex={998} // higher than the configurator gallery
			>
				<SuggestedStore />
				{demoNotice && <DemoNotice />}
				<SalesAgentNotice />
				<Header menuItems={mainMenu} type={headerType} variant={headerVariant} look={headerLook} />
			</StickyHeadroomAutoHeight>
		),
		[
			demoNotice,
			headerLook,
			headerType,
			headerVariant,
			mainMenu,
			overlap,
			stickyHeaderEnabled,
			stickyHeaderHideOnScrollDown,
		]
	)
	const afterContent = useMemo(() => <Footer menus={footerMenu} variant={footerVariant} />, [footerMenu, footerVariant])
	const containerProps = useMemo(
		() => ({
			sx: {
				...(stickyHeaderEnabled && !overlap
					? { marginTop: `var(--header-height)`, transition: mainContainerMarginTopTransition }
					: {}),
			},
		}),
		[stickyHeaderEnabled, overlap, mainContainerMarginTopTransition]
	)

	return (
		<Main
			ignoreBeforeContentSpace={ignoreBeforeContentSpace}
			beforeContent={beforeContent}
			afterContent={afterContent}
			layout={layout}
			containerProps={containerProps}
		>
			{children}
		</Main>
	)
}

export default MainLayout
